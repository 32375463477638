import styled from "styled-components"

export const Figure = styled.figure`
  border: 1px solid #dbdbdb;
  text-align: center;
  border-radius: 4px;
  padding: 7px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  width: 100px;
  margin: 4px;
`

export const FlexDiv = styled.div`
  display: ${props => props.displayDiv || "block"};
  width: ${props => props.width || "100%"};
  max-width: ${props => props.maxWidth || "100%"};
  flex-wrap: ${props => props.flexWrap || "nowrap"};
  justify-content: ${props => props.justifyContent || "normal"};
  margin: ${props => props.margin || "0"};
  text-align: ${props => props.textAlign || ""};
  min-height: ${props => props.minHeight || ""};
`

export const InputButton = styled.input`
  width: ${props => props.width || "50px"};
  height: ${props => props.height || "42px"};
  background: ${props =>
    props.background || "linear-gradient(to top, #666, #3d3d3d)"};
  float: left;
  color: white;
  font-size: 18px;
  outline: none;
  border-radius: 8px;
  border: none;
  margin-left: 7px;
  box-shadow: 0px 3px 2px #000;
  position: relative;
  margin-bottom: 10px;
  &:active {
    box-shadow: none;
    top: 2px;
  }
`

export const ColorImput = styled.input`
  -webkit-appearance: none;
  border: none;
  width: 32px;
  height: 32px;
  cursor: pointer;
  & :focus {
    outline: 0;
  }
`

export const CheckBox = styled.input`
  position: relative;
  width: 1.5em;
  height: 1.5em;
  color: black;
  border: 1px solid black;
  border-radius: 4px;
  border-color: ${props => props.borderColor || "black"};
  appearance: none;
  outline: 0;
  cursor: pointer;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
  &::before {
    position: absolute;
    content: "";
    display: block;
    top: 2px;
    left: 7px;
    width: 8px;
    height: 14px;
    border-style: solid;
    border-color: white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
  }
  &:checked {
    color: white;
    border-color: ${props => props.color || "#34a853"};
    background: ${props => props.backgroundColor || "#34a853"};
    &::before {
      opacity: 1;
    }
  }
`
export const KeyboardButton = styled.button`
  width: ${props => props.width || "50px"};
  height: ${props => props.height || "42px"};
  background: linear-gradient(to top, #666, #3d3d3d);
  float: left;
  color: white;
  font-size: 18px;
  border-radius: 8px;
  border: none;
  margin-left: 7px;
  box-shadow: 0px 3px 2px #000;
  position: relative;
  margin-bottom: 10px;
  outline: none !important;
  &:active {
    box-shadow: none;
    top: 2px;
  }
`

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  & th,
  td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }
  & thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }
  & tbody + tbody {
    border-top: 2px solid #dee2e6;
  }
`

export const Notification = styled.div`
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
  background-color: ${props => props.background || "whitesmoke"};
  color: ${props => props.color || "black"};
  border-radius: 4px;
  position: relative;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
  & strong {
    color: currentColor;
  }
  & > .deleteClose {
    right: 0.5rem;
    position: absolute;
    top: 0.1rem;
    & span {
      font-size: 2rem;
    }
  }
`

export const BoxColorido = styled.div`
  width: 15vw;
  max-width: 200px;
  height: 50vh;
  @media screen and (max-width: 1000px) {
    width: 90vw;
    max-width: 90vw;
    height: 10vh;
  }
`

export const ContainerMatriz = styled.div`
  overflow: hidden;
  &.rOw {
    position: relative;
  }
  & ul.listUnstyled {
    position: relative;
    padding: 0;
    & li {
      margin: 0;
      padding: 0;
      display: table-cell;
      @media screen and (max-width: 1000px) {
        margin: 0;
        padding: 0;
        display: block;
      }
      & ${BoxColorido} {
        position: relative;
        -webkit-transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);

        & p {
          position: absolute;
          width: 110px;
          height: 30px;
          left: calc(50% - 55px);
          bottom: 30px;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 20px;
          color: rgba(255, 255, 255, 0.8);
          font-family: "Varela Round", sans-serif;
          text-align: center;
          letter-spacing: 2px;
          @media screen and (max-width: 1000px) {
            left: calc(50% - 60px);
            top: 46%;
          }
        }
      }
    }
  }
  @-webkit-keyframes rodar {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rodar {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spinAround {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  @keyframes spinAround {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`

export const TabsPages = styled.div`
  -webkit-overflow-scrolling: touch;
  align-items: stretch;
  display: flex;
  font-size: 1rem;
  justify-content: space-between;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  & .likeAButton {
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
    align-items: center;
    border-bottom-color: #dbdbdb;

    display: flex;
    justify-content: center;
    margin-bottom: -1px;
    padding: 0.5em 1em;
    vertical-align: top;
    & :hover {
      background-color: whitesmoke;
      border-bottom-color: #dbdbdb;
    }
  }
  & li {
    display: block;
    & button {
      background-color: transparent;
      color: #007bff;
      outline: none;
    }
  }
  & .onThisPage .likeAButton {
    color: #4a4a4a;
    background-color: white;
    border-color: #dbdbdb;
    border-bottom-color: transparent !important;
  }
  & ul {
    align-items: center;
    border-bottom-color: #dbdbdb;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: flex-start;
    & li {
      margin-bottom: 0;
    }
  }
`

export const LeafletCard = styled.div`
  border-radius: 10px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0px 0 1px rgba(10, 10, 10, 0.02);
  margin-bottom: 1rem;
  max-width: 600px;
  min-height: 300px;
  height: ${props => props.height || "300px"};
  & .leaflet-container {
    border-radius: 10px;
  }
`

export const ColumnDiv2 = styled.div`
  text-transform: capitalize;
  column-count: 2;
  @media screen and (max-width: 768px) {
    column-count: 1;
  }
`

export const PopupModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.603);
`
export const ModalDialog = styled.div`
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
  &:before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    content: "";
  }
  @media (min-width: 576px) {
    max-width: 500px;
    margin: 1.75rem auto;
    min-height: calc(100% - 3.5rem);
    &:before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: -moz-min-content;
      height: min-content;
    }
  }
`

export const ModalContent = styled.div`
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
`

export const ModalHeader = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
`

export const ModalTitle = styled.h5`
  margin-bottom: 0;
  line-height: 1.5;
`
export const ModalBody = styled.div`
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
`
export const ModalFooter = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  & > * {
    margin: 0.25rem;
  }
`

export const TextArtTextArea = styled.textarea`
  padding: 1rem;
  cursor: pointer;
  border: 1px solid transparent;
  justify-content: flex-start;
  background-color: white;
  border-color: #dbdbdb;
  border-radius: 4px;
  margin-bottom: 0.75rem;
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
  display: block;
  height: auto;
  font-size: 0.5rem;
  height: 100%;
  @media screen and (max-width: 768px) {
    font-size: 5px;
  }
`
export const CodeEditorCard = styled.div`
  max-width: 500px;
  background: rgb(55, 65, 75);
  font-family: monospace;
  font-size: 15px;
  border-radius: 5px;
  & .buttons {
    box-sizing: border-box;
    border-bottom: 1px solid rgb(45, 55, 65);
    padding: 10px;
  }
  & .button {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    display: inline-block;
    &:nth-child(1) {
      background: rgb(250, 50, 50);
    }
    &:nth-child(2) {
      background: rgb(250, 250, 50);
      margin-left: 5px;
    }
    &:nth-child(3) {
      background: rgb(50, 250, 50);
      margin-left: 5px;
    }
  }
  & .editor-content {
    padding: 15px;
  }
  & .editor-content .editor-line {
    margin: 5px;
    color: rgb(195, 215, 225);
    counter-increment: line;
  }

  & .editor-content .editor-line::before {
    content: counter(line);
    margin-right: 15px;
  }
  & .editor-content .editor-line span.tab {
    margin-right: 15px;
  }
  & .editor-content .editor-line span.tab {
    margin-right: 15px;
  }
  & .editor-content .editor-line span.element {
    color: #569cd6;
  }
  & .editor-content .editor-line span.quotes {
    color: #d69d85;
  }
  & .editor-content .editor-line span.classColor {
    color: #9cdcfe;
  }
`
